<template>
  <div ref="container" class="custom-cursor container" :data-is-loaded="isLoaded">
    <CloudinaryImage
      v-if="outerImageResourceId"
      :public-id="outerImageResourceId"
      :options="{ width: 146, height: 146 }"
      :image-classes-override="['outer-image']"
      :transparent="true"
    />
    <CloudinaryImage
      v-if="innerImageResourceId"
      :public-id="innerImageResourceId"
      :options="{ width: 100, height: 100 }"
      :image-classes-override="['inner-image']"
      :transparent="true"
    />
  </div>
</template>

<script setup lang="ts">
import { AssetCloudinary } from '~/types/contentstack';
import { getFirstIndex } from '~/util/arrays';

const props = defineProps<{
  outerCloudinaryAsset?: AssetCloudinary[];
  innerCloudinaryAsset?: AssetCloudinary[];
  text?: string;
}>();

const container = ref<HTMLElement | null>(null);

function handleMouseMove(event: MouseEvent) {
  const x = event.clientX;
  const y = event.clientY;
  if (container.value) {
    container.value.style.transform = `translate(${x}px, ${y}px)`;
  }
}

const isLoaded = ref(false);

const outerImageResourceId = getFirstIndex(props.outerCloudinaryAsset)?.public_id;
const innerImageResourceId = getFirstIndex(props.innerCloudinaryAsset)?.public_id;

onMounted(() => {
  document.addEventListener('mouseenter', handleMouseMove);
  document.addEventListener('mousemove', handleMouseMove);
});

onUnmounted(() => {
  document.removeEventListener('mouseenter', handleMouseMove);
  document.removeEventListener('mousemove', handleMouseMove);
});
</script>

<style lang="scss" scoped>
.container {
  @include local-mixins.tablet_and_mobile {
    display: none;
  }

  --size: 146px;
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  display: grid;
  place-items: center;
  grid-template-areas: 'stack';
  width: var(--size);
  height: var(--size);
  border-radius: 9999px;
  cursor: none;
  pointer-events: none;
  margin-top: calc(var(--size) / -2);
  margin-left: calc(var(--size) / -2);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  transform: translate(-100%, -100%);

  &[data-is-loaded='false'] {
    opacity: 0;
  }
}

.outer-image {
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  overflow: hidden;
  object-fit: cover;
  grid-area: stack;
  animation: rotate 10s linear infinite;
}

.inner-image {
  width: 100px;
  height: 100px;
  border-radius: 9999px;
  overflow: hidden;
  object-fit: cover;
  grid-area: stack;
  position: relative;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
